<template>
    <div class="flex flex-col items-center pt-16">
        <img alt="Vue logo" src="images/logo.png" class="w-96 mt-12" />
        <van-button class="mt-8 w-64" round type="success" @click="goClass()">{{ lang[userLang].browse }}</van-button>
        <van-button class="mt-4 w-64" round type="primary" @click="goLogin()">{{ lang[userLang].login }}</van-button>
        <van-button class="mt-4 w-64" round type="primary" @click="goSignup()">{{ lang[userLang].reg }}</van-button>
        <div class="mt-12">
            <van-tag v-if="userLang === 'zh'" class="mr-1" round type="success">zh</van-tag>
            <span v-else class="text-xs cursor-pointer" @click="changeLang('zh')">zh</span>
            <van-tag v-if="userLang === 'en'" class="ml-1" round type="success">en</van-tag>
            <span v-else class="text-xs cursor-pointer" @click="changeLang('en')">en</span>
        </div>
    </div>
</template>

<script>
import lang from "@/langs/FrontLang.js";
import {
    computed
} from "vue";
import {
    useRouter
} from "vue-router";
import {
    useStore
} from "vuex";
import {
    Button,
    Tag
} from "vant";
import Cookies from "js-cookie";

export default {
    components: {
        [Button.name]: Button,
        [Tag.name]: Tag
    },
    setup() {
        const store = useStore();
        store.commit("hideBack");
        store.commit("hideMenu");
        store.commit("setPageTitle", lang[store.state.lang].title);
        const router = useRouter();

        const goLogin = function () {
            router.push("/login");
        };
        const goSignup = function () {
            router.push("/signup/new");
        };
        const goClass = function () {
            router.push("/class/fanmei");
        };
        const changeLang = function (code) {
            store.commit("setLang", code);
            store.commit("setPageTitle", lang[code].title);
            Cookies.set("userLang", code);
        }

        return {
            lang,
            userLang: computed(() => store.state.lang),
            goLogin,
            goSignup,
            goClass,
            changeLang
        };
    }
}
</script>
