export default {
    en: {
        title: "PACDA Dance Manager",
        login: "Sign In",
        reg: "Create Account",
        browse: "Browse Classes"
    },
    zh: {
        title: "泛美舞蹈管理系统",
        login: "登录",
        reg: "注册",
        browse: "浏览课程"
    }
}